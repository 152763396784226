#home {
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: repeat;
  background-color: black;
  // disable the default padding styles
  .app__wrapper {
    padding: 0;
    .copyright {
      display: none;
    }
  }
}

.app__header {
  flex: 1;
  width: 100%;
  height: 100%;
  flex-direction: row;
  padding: 6rem 2rem 0rem;
  @media screen and (min-width: 2000px) {
    padding-top: 8rem;
  }

  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }

  @media screen and (max-width: 450px) {
    padding: 6rem 1rem 2rem;
  }
}

.app__header-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  margin: 0 2rem;
  @media screen and (max-width: 1200px) {
    width: 100%;
    margin-right: 0rem;
  }
}

.app__header-badge {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;

  .badge-cmp,
  .tag-cmp {
    padding: 1rem 2rem;
    background: var(--white-color);
    border-radius: 10px;
    flex-direction: row;
    width: auto;
    border: 1px solid #a1a1a1;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  }

  .tag-cmp {
    flex-direction: column;
    margin-top: 3rem;

    p {
      width: 100%;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      text-align: center;
      font-weight: bold;
    }
  }

  span {
    font-size: 2.5rem;

    @media screen and (min-width: 2000px) {
      font-size: 5rem;
    }
  }

  @media screen and (max-width: 1200px) {
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.app__header-circles {
  flex: 0.75;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  height: 100%;
  margin-left: 1rem;

  div {
    width: 100px;
    height: 100px;
    border-radius: 50%;

    background: var(--white-color);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);

    img {
      width: 60%;
      height: 60%;
    }
  }

  // circles with different height & width

  div:nth-child(1) {
    width: 100px;
    height: 100px;
  }

  div:nth-child(2) {
    margin: 1.75rem;
    width: 150px;
    height: 150px;
  }

  div:nth-child(3) {
    width: 70px;
    height: 70px;
  }

  @media screen and (min-width: 2000px) {
    div:nth-child(2) {
      width: 400px;
      height: 400px;
    }

    div:nth-child(3) {
      width: 170px;
      height: 170px;
    }

    div:nth-child(1) {
      width: 200px;
      height: 200px;
    }
  }

  @media screen and (max-width: 1200px) {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 0;

    div {
      margin: 1rem;
    }
  }
}

.app__header-img {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative;

  img {
    border-radius: 10px;
    max-height: 300px;
    width: 100%;
    z-index: 1;
  }

  .overlay_circle {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 40px;
    z-index: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  @media screen and (max-width: 1200px) {
    margin: 4rem 0;
  }
}

h1 {
  background: #4b23cf;
  background: linear-gradient(to left, #4b23cf 21%, #210303 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.btn-grad {
  background-image: linear-gradient(
    to right,
    #4b23cf 0%,
    #01124f 51%,
    #4b23cf 100%
  );
  font-family: inherit;
  margin: 5px;
  margin-top: 20px;
  padding: 7px;
  display: flex;
  align-items: center;
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 10px;
  display: block;
  border: 0;
  cursor: pointer;
  a {
    text-decoration: none;
    color: white;
  }
}

.btn-grad:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}
@media screen and (min-width: 565px) {
  .btn-grad {
    padding: 10px 30px;
  }
}
.mobile__social__links {
  display: flex;
  gap: 1rem;
  margin: 2rem 0;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 350px;

  a {
    border: 1px solid #a1a1a1;
    padding: 0.5rem;
    border-radius: 5px;
    color: black;
    display: flex;
    background-color: white;
    gap: 0.2rem;
    transition: all 0.3s ease-in-out;
    font-weight: 500;
    span {
      font-size: 0.8rem;
    }
    &:hover {
      background-color: transparent;
      color: #a1a1a1;
      border: 1px solid white;
    }
  }
}
