.app__footer {
  flex: 1;
  width: 100%;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.app__footer-cards {
  width: 60%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  margin: 4em 2rem 2rem;

  .app__footer-card {
    min-width: 290px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    margin: 1rem 0;
    padding: 1rem;
    border-radius: 10px;
    cursor: pointer;
    background-color: #fef4f5;

    transition: all 0.3s ease-in-out;

    img {
      width: 40px;
      height: 40px;
      margin: 0 0.7rem;
    }

    p {
      font-weight: 500;
    }
    a {
      text-decoration: none;
      font-weight: 500;
    }
    &:hover {
      box-shadow: 0 0 25px #fef4f5;
    }

    @media screen and (max-width: 450px) {
      width: 100%;
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.app__footer-cards .app__footer-card:last-child {
  background-color: #f2f7fb;
}

.app__footer-cards .app__footer-card:last-child:hover {
  box-shadow: 0 0 25px #f2f7fb;
}
